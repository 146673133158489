

.base{
    background-color: #ccc;
    padding: 4px;
    border-radius: 4px;
    font-size: 0.8em;
    cursor: pointer;
}
.base:hover{
    opacity: 0.6;
}
.base.confirm{
    background-color: lightseagreen;
    color: white;
}




