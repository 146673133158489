


.breakupDetail{

}
.breakupDetail>.timeContainer{

}
.breakupDetail>.reason{
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 4px;
}
.breakupDetail>.reason>.a{
    font-weight: bold;
    font-size: 0.9em;
    padding: 1em;
}
.breakupDetail>.reason>.b{
    background-color: #ddd;
    word-break: break-all;
    white-space: break-spaces;
    padding: 1em;
    border-radius: 4px;
}
.breakupDetail>.reason .c{
    font-size: 0.9em;
    margin: 1em 0;
    color: #aaa;
}

.breakupDetail>.timeContainer>.time{
    font-weight: bold;
    background-color: gold;
    padding: 4px;
    border-radius: 4px;
}


.commentBtn{
    margin-top: 2em;
}
.commentBtn>span{
    background-color: goldenrod;
    padding: 4px;
    border-radius: 4px;
    font-size: 0.9em;
    color: white;
    border: 1px dashed yellow;
}





.preOrNext{
    padding-top: 2em;
    display: flex;
    gap: 2em;
}
.baseBtn{
    background-color: lightgrey;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 1.3em;
    cursor: pointer;
}


.addReason{
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 4px;
    margin-top: 1em;
}
.addReason .a{
    font-weight: bold;
    font-size: 0.9em;
    padding: 1em;
}
.addReason .b{
    background-color: #ddd;
    word-break: break-all;
    white-space: break-spaces;
    padding: 1em;
    border-radius: 4px;
}
.addReason .c{
    font-size: 0.9em;
    margin: 1em 0;
    color: #aaa;
}



.addComment{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 90px;
    background-color: white;
}

.addComment>div{
    padding: 1em;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}
.addComment .btns{
    display: flex;
    gap: 1em;
    margin: 1em 0;
}