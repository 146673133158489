

.item{
    padding: 1em;
    margin:1em 0;
    user-select: none;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    background-color: #f5f5f5;
}


.item .postTitle{
    font-size: 0.7em;
    opacity: 0.5;
}
.item .postTitle>p{
    background-color: #eee;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 1em;
}
.item .postTitle a{
    text-decoration: none;
    color: black;
}


.item .replyContentContainer{
    
}
.item .replyContentContainer .replyContent{
    word-break: break-all;
    white-space: break-spaces;
}
.item .replyContentContainer>.hideReplyContent{
    word-break: break-all;
    white-space: break-spaces;
    height: 4em;
    overflow: hidden;
}
.item .replyContentContainer a{
    text-decoration: none;
    color: black;
}

.btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666;
    font-size: 0.8em;

}
.btns .btn{
    gap: 1em;
    display: flex;
}
.btns .btn>span{
    padding: 4px;
    border-radius: 3px 3px 0 0;
    display: flex;
    align-items: center;
}