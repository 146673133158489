
.bg{
    padding: 6px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    display: flex;
}


.bg>.nodes{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0,0,0,.1);

}
.nodes>.container{
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    background: #f5f5f5;
    padding: 0 10px;
    border-radius: 3px;

}
.nodes .node{
    padding: 4px 0;
}
.nodes .node>a{
    text-decoration: none;
    color: #333;
    font-size: 1.1em;
    font-weight: bold;
    border-bottom: 1px solid darkolivegreen;
}
.nodes .node>a:hover{
    border-bottom: 1px solid yellow;
}


.container>.pager{
    text-align: center;
    padding-top: 1em;
}
.pager>span{
    border: 1px solid green;
    margin:1em 3px;
    padding: 3px;
    border-radius: 3px;
}
.pager>span:active{

}