

.container{
    padding: 0 16px;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
}


.hint{
    padding: 14px;
    border-radius: 4px;
    text-indent: 2em;
    font-size: 1em;
    background: #f7f7f7;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}


.install{
    width: 100%;
    overflow-x: auto; /* 允许横向滚动 */
    white-space: nowrap; /* 防止子元素换行 */
    scroll-behavior: smooth; /* 使滚动具有平滑动画效果 */
}
.install>div{
    display: inline-block;
    width: 80%;
    margin-right: 1em;
    vertical-align: top;
}


.ios{
    font-size: 15px;
    white-space: break-spaces;
    padding: 14px;
    margin-top: 1em;
    border-radius: 4px;
    background: #f7f7f7;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.ios>span , .android>span{
    font-weight: bold;
    margin-bottom: 1em;
    display: inline-block;
    color: #635737;
}
.ios>div , .android>div{
    text-indent: 1em;
    word-break: break-all;
}
.ios img{
    width: 100%;
}


.android{
    font-size: 15px;
    white-space: break-spaces;
    padding: 14px;
    margin-top: 1em;
    border-radius: 4px;
    background: #f7f7f7;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.android p{
    text-align: center;
}
.bottom{
    margin: 2em 0;
    font-size: 15px;
    white-space: break-spaces;
    padding: 14px;
    border-radius: 4px;
    background: #f7f7f7;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    text-indent: 2em;
}


.androidDownload{
    background: green;
    user-select: none;
    padding: 6px;
    border-radius: 3px;
    color: antiquewhite;
    font-weight: bold;
    font-size: 18px;
    box-shadow: 4px 4px 4px #cacaca, -3px -3px 4px #ffffff;
    transition: all 0.3s;
}
.androidDownload:active{
    box-shadow: none;
}