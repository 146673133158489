

.container{
    padding: 16px;
}
.header{
    display: flex;
    margin: 2em 0;
}
.header>span{
    font-weight: bold;
    font-size: 1.2em;
}

.title{
    font-weight: bold;
    font-size: 1.1em;
    text-indent: 2em;
}

.goal{
    background-color: #f5f5f5;
    padding: 2em 6px;
    border-radius: 4px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.goal>div{
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5em;
    color: #666;
}
.goal>p{
    margin: 0;
    text-indent: 2em;
    font-size: 0.9em;
    color: #333;
}

.foot{
    background-color: #f8f8f8;
    padding: 6px;
    margin: 2em 0;
    border-radius: 4px;
    font-size: 0.9em;
    text-indent: 2em;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}