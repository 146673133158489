
.container{
    box-sizing: border-box;
    padding: 10px;
    height: 100%;
    overflow-y: auto;
    background-color: #f5f5f5;
    user-select: none;

}

.container span{
    padding: 8px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    font-size: 14px;
    text-align: center;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.2s;
    display: inline-block;
}

.container span:active{
    box-shadow: none;
}