
.bg{
    margin: 1em 0;
}
.bg>span{
    padding: 6px;
    background-color: #f6f6f6;
}
.bg>span.hide{
    background-color: goldenrod;
}