

.container{
    padding: 0 16px;
    user-select: none;
    height: 100%;
    overflow-y: auto;
    background-color: #f5f5f5;
}
.container>div{

}

/* 顶部 */

.header{
    position: sticky;
    top: 0;
    height: 2.5em;
    padding: 0;
    z-index: 999;
}
.header>div{
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    justify-content: flex-end;
    gap: 1em;
    height: 100%;
}
.dingwei{
    color: white;
    background-color: #666;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}
.shuaxin{
    display: none;
}
.add{
    color: white;
    background-color: #666;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

/* 我的节点 */
.myNodesBg{
    padding: 10px;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin-bottom: 2em;
    margin-top: 1em;
    box-shadow: 1px 1px 1px #d3d3d3, -1px -1px 1px #fff;
    margin-left: 4px;
    margin-right: 4px;
}
.myNodes{
    display: flex;
    flex-wrap: wrap;
    gap: 0.3em;
}
.myNodes .archivedTopic{
    padding: 4px 0;
}
.myNodes .archivedTopic>a{
    text-decoration: none;
    font-size: 1em;
    color: #333;
    border-bottom: 1px solid darkolivegreen;
}
.myNodes .archivedTopic>a:hover{
    border-bottom: 1px solid green;
}
.toAllTopicBtn{
    margin-top: 0.5em;
}
.toAllTopicBtn a{
    text-decoration: none;
    color: #333;
    font-size: 0.9em;
    border-bottom: 1px solid darkolivegreen;
}



/*  */
.contents{
    font-size: 1em;
    border-radius: 4px;
    background-color: #f1f1f1;
    margin-bottom: 2em;
}


/* 最新 */
.newest{

}
.newest a{
    text-decoration: none;
    color: #333;
    font-size: 1em;
}
.newest>div{
    padding: 6px;
    margin-bottom: 2em;
}

/* 置顶的话题 */
.newest .stickedPost{
    margin-top: 1.5em;
    background-color: #f5f5f5;
    box-shadow: 1px 1px 1px #d3d3d3, -1px -1px 1px #fff;
    margin-left: 4px;
    margin-right: 4px;
}
.newest .stickedPost>div{
    padding: 6px;
    padding-top: 10px;
}
.newest .stickedPost a{
    text-decoration: none;
    color: #333;
    font-weight: bold;
    word-break: break-all;
    border-bottom: 1px solid goldenrod;
    font-size: 1em;
}
.newest .newestPosts{
    padding: 12px;
    border-radius: 3px;
    margin-left: 4px;
    margin-right: 4px;
    box-shadow: 1px 1px 1px #d3d3d3, -1px -1px 1px #fff;
    
}
.newest .newestPosts>div{
    padding-top: 1.5em;
    padding-bottom: 6px;
    position: relative;
    line-height: 1.8em;
    border-bottom: 1px solid #dfdfdf;
}





/* 精选话题 */
.selectedPosts{
    padding: 12px;
    margin-top: 1.5em;
    box-shadow: 1px 1px 1px #d3d3d3, -1px -1px 1px #fff;
    border-radius: 3px;
    margin-left: 4px;
    margin-right: 4px;
}
.selectedPosts a{
    text-decoration: none;
    color: #333;
    font-size: 1em;
}
.selectedPosts>div{
    padding-top: 1.5em;
    padding-bottom: 6px;
    line-height: 1.8em;
    border-bottom: 1px solid #eee;
}



/* 精选回复 */
.selectedReplyContainer{
    margin-top: 1.5em;
}
.selectedReplyItem{
    padding: 6px;
    margin-bottom: 0.5em;
    user-select: none;
    box-shadow: 1px 1px 1px #d3d3d3, -1px -1px 1px #fff;
    background-color: #f5f5f5;
    margin-left: 4px;
    margin-right: 4px;
}

.selectedReplyItem .postTitle{
    font-size: 0.7em;
    opacity: 0.5;
}
.selectedReplyItem .postTitle>p{
    background-color: #eee;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 1em;
}
.selectedReplyItem .postTitle a{
    text-decoration: none;
    color: black;
    -webkit-tap-highlight-color:rgba(255,0,0,0);
}

.selectedReplyItem .replyContentContainer{
    
}
.selectedReplyItem .replyContentContainer .replyContent{
    word-break: break-all;
    white-space: break-spaces;
    line-height: 1.8em;
}
.selectedReplyItem .replyContentContainer>.hideReplyContent{
    word-break: break-all;
    white-space: break-spaces;
    height: 4em;
    overflow: hidden;
    line-height: 1.8em;
}
.selectedReplyItem .replyContentContainer a{
    text-decoration: none;
    color: #666;
    -webkit-tap-highlight-color:rgba(255,0,0,0);
}

.selectedReplyItem .btns{
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.selectedReplyItem .btns .btn{
    gap: 1em;
    display: flex;
}
.selectedReplyItem .btns .btn>span{
    font-size: 0.8em;
    padding: 4px;
    border-radius: 3px 3px 0 0;
    display: flex;
    align-items: center;
}
.selectedReplyItem .btns .date{
    font-size: 0.8em;
    opacity: 0.5;
}


@media (min-width: 1024px) {
    .shuaxin{
        color: white;
        background-color: #666;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
    }
}
