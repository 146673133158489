

.bg{
   position: fixed; 
   top: 0;
   left: 0;
   right: 0;
   bottom: 90px;
   background-color: #eee;
   z-index: 1000;
}
.bg>div{
    margin: 0 auto;
    top: 4em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    overflow-y: auto;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
}


/* title */
.title{
    display: flex
;
    justify-content: center;
    align-items: center;
    position: relative;
}
.title>.a{
    flex: 0 0 auto;
    font-weight: bold;
    font-size: 19px;
    color: goldenrod;
}
.title>.b{
    position: absolute;
    right: 0;
}

.input>{

}
.input>textarea{
    resize: none;
    display: block;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    height: 16em;
    border-radius: 0;
    font-size: 16px;
    padding: 1em;
    border-radius: 2px;
    border: 1px solid #ccc;
    border: none;
    background-color: white;
    box-shadow: none;

    transition: transform 0.5s ease, box-shadow 0.5s ease;
}
.input>textarea:focus{
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2), -2px -2px 4px white;
}


.locateBtn{
    text-align: center;
    display: block;
    padding: 8px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;

    transition: transform 0.1s ease, box-shadow 0.1s ease;
}
.locateBtn:active{
    box-shadow: none
}