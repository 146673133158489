

.container{
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    padding-bottom: 1em;
}
.container>div{
    padding: 0 16px;
}

.topBtns{
}
.topBtns>p{
    display: flex;
    gap: 1em;
    color: #333;
    font-size: 0.9em;
    font-weight: bold;
}
.topBtns>p>span{
    padding: 8px;
    background: orange;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    text-align: center;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.3s;
}

.topBtns>p>span:active{
    box-shadow: none;
}

.items{
    background-color: #eee;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.items>.item{
    padding: 0.5em;
    background-color: #ffc107;
    border-radius: 4px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.3s;
}
.items>.read{
    background-color: #f5f5f5;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.items>.item:active{
    box-shadow: none;
    background-color: #eee;
}
.item>.content{
    font-size: 0.9em;
    font-style: italic;
    margin: 0.3em 0;
}
.item>.date{
    font-size: 0.8em;
    color: #574f3a;
}