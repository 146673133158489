
.items{
    user-select: none;
}
.items>div{
    cursor: pointer;
    padding: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
}
.active{
    background-color: goldenrod;
    color: white;
}



