/* 基础样式 */
.switch {
    position: relative;
    width: 4em;
    height: 2em;
    background: #e0e5ec;
    border-radius: 30px;
    box-shadow: 9px 9px 18px #c8cfd4, -9px -9px 18px #ffffff; /* 新拟态阴影 */
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    overflow: hidden;

  }
  
  /* 左右选项 */
  .option {
    font-size: 14px;
    font-weight: bold;
    z-index: 2;
    color: #888;
    transition: color 0.3s ease;
  }
  
  /* 滑块 */
  .slider {
    position: absolute;
    width: 50%;
    height: 100%;
    background: #e0e5ec;
    border-radius: 30px;
    box-shadow: inset 4px 4px 8px #c8cfd4, inset -4px -4px 8px #ffffff; /* 内部阴影 */
    transition: transform 0.3s ease;
  }
  
  /* 初始状态：滑块在左侧 */
  .slider {
    transform: translateX(0%);
  }
  
  /* 切换到右侧 */
  .switch.active .slider {
    transform: translateX(100%);
  }
  
  /* 选中状态的文字颜色 */
  .switch.active .option:first-child {
    color: #007bff; /* 高亮颜色 */
  }
  .switch:not(.active) .option:last-child {
    color: #007bff; /* 高亮颜色 */
  }