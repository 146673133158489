

.container{
    padding: 1em;
}

.container>div{
    padding: 1em;
    border: 1px solid #f3f2f2;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    margin-bottom: 1em;
}

.ways{
    background-color: rgb(243, 243, 243);
    padding: 1em;
}