

.container{
    margin: 1em 0;
    width: 100%;
    user-select: none;
}

.container>div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}

.left{
    height: 20em;
    overflow-y: auto;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.5em;
    font-size: 1.2em;
}
.left>.province{
    background-color: #ccc;
    padding: 4px;
    border-radius: 3px;
}


.left::-webkit-scrollbar { 
    width: 12px;
}
.left::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    }
    .left::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 20px;
}
.left::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
}




.province.active{
    background-color: #bde2ff;
    font-weight: bold;
}

.right{
    height: 20em;
    overflow-y: auto;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.5em;
    font-size: 1.2em;
}
.right>.city{
    background-color: #ccc;
    padding: 4px;
    border-radius: 3px;
}
.city.active{
    background-color: #bde2ff;
    font-weight: bold;
}


.header{
    margin: 1em 0;
    display: block;
    padding: 0.5em;
    text-align: center;
    height: 6em;
}
.header>span{
    font-size: 1.5em;
    font-weight: bold;
    border-bottom: 2px solid saddlebrown;
    margin: 1em;
}