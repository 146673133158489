
.container{
    padding: 1em;
    background-color: white;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}


.hint{
    background-color: #eee;
    border-radius: 4px;
    padding: 14px;
    font-size: 14px;
}

.container>.profile{

}
.profile>.item>span{
    color: #666;
    font-size: 1em;
    font-weight: bold;
}

.profile .item{
    border-bottom: 1px solid #ebe5bc;
    display: flex;
    justify-content: space-between;
    line-height: 3em;
}
.profile .item span{
    width: 4em;
}

.profile>.signature{
    border-bottom: 1px solid #ededed70;
    line-height: 2em;

}
.profile>.signature>span{
    color: #666;
    font-size: 1em;
    font-weight: bold;
}
.profile>.signature>div{
    padding: 1em;
    font-size: 0.9em;
    color: rgba(0,0,0,0.8);
    word-break: break-all;
    white-space: break-spaces;
}

.profile .detail{
    border-bottom: 1px solid #ededed70;
}
.profile>.detail>span{
    color: #666;
    font-size: 1em;
    font-weight: bold;
}
.profile>.detail>div{
    word-break: break-all;
    padding: 1em;
    font-size: 0.9em;
    color: rgba(0,0,0,0.8);
    white-space: break-spaces;
}

.profile .hide{
    background-color: #80808024;
    padding: 3px;
    border-radius: 3px;
    margin: 3px 0;
}
.profile .error{
    border: 1px solid red;
    border-radius: 4px;
}


/*  */
.header{
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    user-select: none;
}
.header .gray{
    background-color: #80808024;
    padding: 3px;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.294);
}


