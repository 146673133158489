.bg{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #eeeeeec9;

    display: flex;
    align-items: center;
    justify-content: center;
}
.bg>.container{
    background-color: white;
    min-width: 80%;
    min-height: 10em;

    position: relative;
    padding: 1em;
    border-radius: 4px;
}

.msg{
    font-weight: bold;
}

.btns{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.btns>p{
    display: flex;
    justify-content: center;
    gap: 2em;
}
