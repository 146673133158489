


.newlist{
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    background: #f5f5f5;
    user-select: none;
}

/* 置顶的话题 */
.newlist>.stickedPost{
    padding: 16px;
    border-bottom: 1px solid #e8e9ed;
    background-color: #f5f5f5;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.newlist>.stickedPost>div{
    word-break: break-all;
   
}

.newlist>.stickedPost a{
    text-decoration: none;
    color: #333;
    font-weight: bold;
}



.newlist>.post{
    border-bottom: 1px solid #e8e9ed;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    border-radius: 3px;
    background-color: #f5f5f5;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: box-shadow 0.2s;
}
.newlist>.post:active{
    box-shadow: none;
}

.newlist>.post>div{
    word-break: break-all;
}
.newlist>.post a{
    text-decoration: none;
    color: #333;
    /* border-bottom: 1px solid darkolivegreen; */
    font-weight: bold;
}
.newlist>.post a:hover{
    color: rgba(0, 0, 0,.6);
}
.newlist>.post a:active{
    color: rgba(0, 0, 0,.6);
}

.newlist>.post>.description{
    font-size: 0.9em;
    color: #555;
    max-height: 6em;
    overflow: hidden;
    line-height: 1.5em;
}
.post .ss{
    margin-top: 1em;
    font-size: 0.8em;
    color: #000000b5;
    display: flex;
    gap: 1em;
    justify-content: space-between;
}
.ss>.left{
    display: flex;
    gap: 1em;
}
.ss>.left>span{
    padding: 2px 4px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #008000bd;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.2s;
}
.ss>.date{
    color: #444;
}