

.bg {
    position: relative;
    overflow: hidden;
}

.floatingText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 2rem;
}

.floatingText span {
    font-size: 2.5rem;
    color: rgba(0, 0, 0, 0.1);
    animation: float 3s ease-in-out infinite;
}

.floatingText span:nth-child(2) {
    animation-delay: 1.5s;
}

.decorativeShapes {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.circle, .square, .triangle {
    position: absolute;
    opacity: 0.1;
    animation: rotate 10s linear infinite;
}

.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #007bff;
    top: 20%;
    left: 20%;
}

.square {
    width: 40px;
    height: 40px;
    background: #28a745;
    top: 60%;
    right: 20%;
    animation-duration: 15s;
}

.triangle {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 45px solid #dc3545;
    top: 40%;
    left: 50%;
    animation-duration: 12s;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.container{
    border-radius: 2px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2), -3px -3px 6px rgb(232, 232, 232);
    position: absolute;
    left: 1em;
    right: 1em;
    top: 6em;
    padding: 1em;
    overflow-y: scroll;
}

.container>.header{
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    gap: 2em;
    justify-content: center;
    user-select: none;
}
.container>.header>.activeTab{
    border-bottom: 1px solid black;
}


.loginContainer{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.loginContainer>div{
    display: flex;
    align-items: center;
    border-bottom: 1px solid darkgray;
}
.loginContainer input{
    flex:1;
    border-radius: 0;
    outline: none;
    height: 38px;
    font-size: 16px;
    border: none;
    padding-left: 1em;
}
.loginContainer .btns{
    display: flex;
    gap: 1em;
    color: #666;
}



.register{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.register>div{
    display: flex;
    align-items: center;
    gap: 1em;
    border-bottom: 1px solid darkgray;
}
.register>div>input{
    flex: 1;
    outline: none;
    height: 2.5em;
    font-size: 16px;
    border: none;

    border-radius: 0;
}

.register>span{
    background: chocolate;
    padding: 4px;
    border-radius: 2px;
    text-align: center;
    color: white;
    height: 28px;
    line-height: 28px;
    user-select: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}