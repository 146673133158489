
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.progressContainer {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 400px;
    text-align: center;
}

.progressContainer p {
    margin-top: 10px;
    color: #666;
}

.container{
    padding: 1em;
    max-height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    background: #f5f5f5;
    box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2), inset -4px -4px 2px white;
}
.container>p{
    flex-shrink: 0;
}








.btn{
    display: block;
    padding: 8px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    text-align: center;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;

    transition: all 0.2s;
}
.btn:active{
    box-shadow: none;
}


.imgsContainer{
    display: flex;
    gap: 1em;
}
.imgsContainer>.add{
    width: 20%;
    height: 20vw;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 1px 1px 1px  #d3d3d3,-1px -1px 1px #fff;
}