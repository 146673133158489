

.container{
    box-sizing: border-box;
    padding: 10px;
    height: 100%;
    overflow-y: auto;
    background-color: #eee;
    user-select: none;

}

.container>div{
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 1px  #d3d3d3,-1px -1px 1px #fff;
    margin-bottom: 1em;
}
.container>.top{
    display: flex;
    flex-direction: column;
    gap: 1em;
    background: linear-gradient(to right, #ff980026, #03a9f42b);
}
.container>.top>.user{
    display: flex;
    flex-direction: row;
    gap: 1em;
    box-shadow: 1px 1px 1px  #d3d3d3,-1px -1px 1px #fff;
    padding: 10px;

    transition: all 0.3s;
}
.container>.top>.user:active{
    box-shadow: none;
}
.user>.left{
    display: flex;
    justify-content: center;
    align-items: center;
}
.user>.left>img{
    width: 40px;
    height: 40px;
    border-radius: 4px;
}
.user>.right{
    color: gray;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.user>.right>.nickname{
    font-size: 1.2em;
    font-weight: bold;
    color: #555;
}


.container>.top>.userNum{
    display: flex;
    align-items: baseline;
    gap: 1em;
}
.container>.top>.userNum>span{
    font-size: 0.8em;
    color: #000000a6;
}
.container>.top>.userNum>.pointsLevel{
    background-color: #b7eb8f;
    padding: 0 0.5em;
    border-radius: 3px;
    color: lightgoldenrodyellow;
    font-weight: bold;
}


/* 封禁 */
.ban{
    font-size: 0.8em;
    color: #000000a6;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}


/* 关注和粉丝 */
.top>.followerContainer{
    display: flex;
    gap: 1em;
}
.top>.followerContainer>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666;
    font-size: 14px;
}


/*  */
.more{

}
.more>div{
    border-bottom: 1px solid #d9d7d7;
    margin: 6px 0;
    padding-top: 10px;
    padding-bottom: 6px;
}

.more>div>span{
    font-size: 1.1em;
    font-weight: bold;
    color: #333;
    display: flex;
    gap: 10px;
    align-items: center;
}

