

.container{
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    background-color: #f5f5f5;
}
.container>.first{
    margin-bottom: 2.5em;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
}

.container>.detail{
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    background-color: #f5f5f5;
}
.detail>.postHeader{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.postHeader>.node{
    font-size: 0.8em;
    color: #000000b5;
    font-weight: bold;
}
.postHeader>.right{
    display: flex;
    gap: 1em;
    align-items: baseline;
}
.postHeader>.right .user{
    font-size: 0.8em;
    color: #000000b5;
    cursor: pointer;
}
.postHeader>.right .date{
    font-size: 0.8em;
    color: #000000b5;
}

.detail>.starAndUnstar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}
.detail>.starAndUnstar>.ss{
    font-size: 0.8em;
    color: #000000b5;
    display: flex;
    gap: 1em;
}
.detail>.starAndUnstar>.ss>span{
    padding: 2px 4px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #008000bd;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.2s;
}
.detail>.starAndUnstar>.ss>.star{
    color: white;
    background-color: #d3d3d3;
}
.detail>.starAndUnstar>.shareAndArchive{
    display: flex;
    gap: 1em;
    align-items: center;
}
.detail>.starAndUnstar>.shareAndArchive>span{
    font-size: 0.8em;
    color: #000000b5;
    padding: 2px 4px;
    border-radius: 3px;
    display: flex;
    align-items: center;

}
.detail>.starAndUnstar>.shareAndArchive>.archived{
    background-color: lightgray;
}
.detail>.starAndUnstar>.shareAndArchive>.unarchived{
    
}
.detail>.starAndUnstar>.shareAndArchive>.share{
    
}

.detail .postDesciption{
    white-space: break-spaces;
    word-break: break-all;
    line-height: 1.8em;
    font-size: 1em;
    margin-top: 2em;
    color: #666;
}
.postDesciption>.descriptionHide{
    word-break: break-all;
    white-space: pre-wrap;
    max-height: 8em;
    overflow: hidden;
}
.postDesciption>.description{
    word-break: break-all;
    white-space: pre-wrap;
}
.detail>.title{
    margin-top: 2em;
    word-break: break-all; 
}
.detail>.title>span{
    color: #333;
    font-size: 1.1em;
    font-weight: bold;
}







.container>.replys{
    display: flex;
    flex-direction: column;
    gap: 16px;

}
/* 回复的筛选 */
.replys>.replysFilter{
    display: flex;
    align-items: center;
    gap: 1em;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 0.4em;
    font-size: 0.8em;
    user-select: none;
}
.replys>.replysFilter>span.active{
    font-weight: bold;
}

.replys>.reply{
    border-bottom: 1px solid lightgray;
    padding-bottom: 4px;
}
.reply>.replyId{
    font-size: 0.6em;
    color: darkolivegreen;
    text-align: end;
}
.reply>.replyHeader{
    display: flex;
}
.replyHeader>.user{
    font-size: 0.8em;
    color: #000000b5;
    flex: 1;
    cursor: pointer;
}
.replyHeader>.user:hover{
    color: black;
}
.replyHeader>.date{
    font-size: 0.8em;
    color: #000000b5;
}

.reply>.replyContent{
    white-space: break-spaces;
    word-break: break-all;
    line-height: 1.8em;
    font-size: 1em;
    color: #666;
}
.reply>.replyContentHide{
    white-space: break-spaces;
    word-break: break-all;
    line-height: 1.8em;
    max-height: 8em;
    overflow: hidden;
    font-size: 1em;
    color: #666;
}

.reply>.replyFoot{
    font-size: 0.8em;
    opacity: 0.8;
    display: flex;
    gap: 1em;
    align-items: center;
}
.replyFoot>span{
    padding: 2px 4px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #008000bd;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.2s;
}
.replyFoot>.active{
    color: #000000b5;
    background-color: lightgray;
    padding: 2px 4px;
    border-radius: 3px;
}


.btn{
    margin: 1em 0;
    display: inline-block;
    padding: 5px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    text-align: center;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.2s;
}
.btn:active{
    box-shadow: none;
}