

.container{
    position: fixed;
    top: 0;
    height: 50%;
    left: 0;
    right: 0;
    bottom: 90px;
    z-index: 999;
    background-color: aqua;
}


.cornerBorder{
    box-sizing: content-box;
    touch-action: none;
    position: relative;
}

/* 四个角的公共样式 */
.cornerBorder::before,
.cornerBorder::after {
  content: '';
  position: absolute;
  width: 16px;  /* 角标长度 */
  height: 16px; /* 角标宽度 */
  border: 2px solid goldenrod; /* 边框粗细和颜色 */
}

/* 左上角 */
.cornerBorder::before {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

/* 右下角 */
.cornerBorder::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}



.btns{
  display: flex;
  gap: 1em;
}
.btn{
  display: inline-block;
  padding: 8px;
  background: #f1f1f1;
  color: goldenrod;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
  transition: all 0.2s ease;
}
.btn:active{
  box-shadow: none;
}