



.bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 90px;
    background-color: antiquewhite;
    z-index: 2000;

}
.bg>div{
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bg>div>.img{
    width: 80%;
    max-width: 12em;
    height: auto;
    padding: 16px;
    background: white;
    margin-top: 16px;
    border-radius: 3px;

}

.bg p{
    font-size: 1.5em;
    margin-top: 16px;
    color: #333;
    display: flex;
    gap: 1em;
}
.bg p>span{
    display: inline-block;
    padding: 8px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
    transition: all 0.2s ease;
}
.bg p>span:active{
    box-shadow: none;
}







/* UploadComponent.module.css */

.uploadding {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading {
    text-align: center;
}

.loadingIcon {
    margin-bottom: 10px;
}

.circular {
    animation: rotate 2s linear infinite;
    height: 40px;
    transform-origin: center center;
    width: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {
    100%, 0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #66ff33;
    }
    80%, 90% {
        stroke: #f37735;
    }
}

.loadingText {
    font-size: 16px;
    font-weight: bold;
}


