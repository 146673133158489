

.posts{
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    background: #f5f5f5;
    user-select: none;
}
.post{
    padding: 16px;
    border-bottom: 1px solid #e8e9ed;
    display: flex;
    flex-direction: column;
    gap: 10px;

    background-color: #f5f5f5;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.2s ease;
}
.post:active{
    box-shadow: none;
}

.title{
    margin-bottom: 1em;
}
.title>a{
    text-decoration: none;
    color: #333;
    font-size: 1em;
    font-weight: bold;
}

.description{
    font-size: 0.9em;
    color: #555;
    max-height: 6em;
    overflow: hidden;
}
