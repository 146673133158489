

.bg{
    padding: 1em;
}

.bg>.items{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
    font-weight: bold;
    padding: 10px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.bg>.items>div{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.bg>.items>div>.hint{
    color: goldenrod;
    font-weight: lighter;
}




.alterBg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    background: rgba(0,0,0,0.2);
    user-select: none;
}


.alterBg>.base{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    background: white;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    transition: transform 0.5s ease;
}
.alterBg>.base>.title{
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding: 1em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-color: white;
}
.alterBg>.base>.description{
    text-align: center;
    margin: 0;
    padding: 1em;
    background-color: white;
}
.alterBg>.base>.items{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: white;
  
}
.alterBg>.base>.items>div{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}
.alterBg>.base>.cancle{
    background-color: white;
    margin: 0;
    margin-top: 1em;
    text-align: center;
    height: 3em;
    line-height: 3em;
}