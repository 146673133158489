






.preOrNext{
    padding-top: 2em;
    display: flex;
    gap: 2em;
}
.baseBtn{
    background-color: lightgrey;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 1.3em;
    cursor: pointer;
}