

.container{
    padding: 1em;
    height: 100%;
    overflow-y: auto;
}

.header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
}

.inputs{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;
    padding: 10px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
}