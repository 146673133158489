



.hint{
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 4px;
}
.hint>div>p{
    margin: 0.5em 0;
}
.hint>.a{
    font-weight: bold;
    color: goldenrod;
}
.hint>.b{
    font-size: 0.9em;
    background-color: gray;
    padding: 4px;
    border-radius: 4px;
    text-indent: 2em;
    color: floralwhite;
}





.preOrNext{
    padding-top: 2em;
    display: flex;
    gap: 2em;
}
.baseBtn{
    background-color: lightgrey;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 1.3em;
    cursor: pointer;
}


.confirmBtns{
    display: flex;
    padding: 1em 0;
}
.confirmBtns>div{
    display: flex;
    gap: 1em;
}
.confirmBtns>div>span{
    background-color: #f5f5f5;
    padding: 6px;
    border-radius: 4px;
    font-size: 0.8em;
    color: #666;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}
.confirmBtns>div>span:active{
    box-shadow: none;

}




.decision{
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-top: 2em;
}
.decision>div{
    padding: 1em;
}
.decision>div>p{
    margin: 0;
    margin-bottom: 0.5em;
}
.decision>div>div{
    background-color: #cccccc21;
    padding: 0.5em;
    border-radius: 4px;
    word-break: break-all;
    white-space: break-spaces;
}
.decision .btn{
    font-size: 0.8em;
    background-color: goldenrod;
    padding: 4px;
    border-radius: 4px;
}
.decision .date{
    font-size: 0.8em;
    color: #808080;
}