
.profile{

}
.profile>.item{
    border-bottom: 1px solid #ededed70;
    display: flex;
    justify-content: space-between;
    line-height: 3em;
    user-select: text;
}
.profile>.item>span{
    font-size: 1em;
    font-weight: bold;
    color: #666;
}
.profile>.signature{
    border-bottom: 1px solid #ededed70;
    line-height: 2em;
}
.profile>.signature>span{
    color: #666;
    font-size: 1em;
    font-weight: bold;
}
.profile>.signature>div{
    padding: 1em;
    font-size: 0.9em;
    color: rgba(0,0,0,0.8);
    word-break: break-all;
    white-space: break-spaces;
}

.profile>.detail{
    border-bottom: 1px solid #ededed70;
}
.profile>.detail>span{
    color: #666;
    font-size: 1em;
    font-weight: bold;
}
.profile>.detail>div{
    padding: 1em;
    font-size: 0.9em;
    color: rgba(0,0,0,0.8);
    word-break: break-all;
    white-space: break-spaces;
}

.profile .hide{
    background-color: #80808024;
    padding: 3px;
    border-radius: 3px;
    margin: 3px 0;
}



.confirmBtns{
    display: flex;
    padding: 1em 0;
}
.confirmBtns>div{
    display: flex;
    gap: 1em;
}
.confirmBtns>div>span{
    background-color: #f5f5f5;
    padding: 6px;
    border-radius: 4px;
    font-size: 0.8em;
    color: #666;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}
.confirmBtns>div>span:active{
    box-shadow: none;
}


.baseBtn{
    background-color: lightgrey;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 1.3em;
    cursor: pointer;


}






.decision{
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-top: 2em;
}
.decision>div{
    padding: 1em;
}
.decision>div>p{
    margin: 0;
    margin-bottom: 0.5em;
}
.decision>div>div{
    background-color: #cccccc21;
    padding: 0.5em;
    border-radius: 4px;
    word-break: break-all;
    white-space: break-spaces;
}
.decision .btn{
    font-size: 0.8em;
    background-color: goldenrod;
    padding: 4px;
    border-radius: 4px;
}
.decision .date{
    font-size: 0.8em;
    color: #808080;
}

.nextBtn{
    margin: 1.5em 0;
}