
/* 设置 */
.settingBg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f5f5f5;
    color: #444;
    padding: 1em;
    z-index: 100;
    overflow-y: scroll;
    box-sizing: border-box;
}
.setting .items{
    display: flex;
    flex-direction: column;
    gap: 1em;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    padding: 10px;
    border-radius: 3px;
    background-color: #f5f5f5;
}
.setting .items>div{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    padding-top: 1em;
}
.setting .items>div>.hint{
    color: #666;
    font-weight: lighter;
}


.first,.second{
    display: flex;
    flex-direction: column;
    gap: 1em;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    padding: 10px;
    border-radius: 3px;
    background-color: #f5f5f5;
    margin-bottom: 1em;
}
.first>div,.second>div{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    padding-top: 1em;
}
.first>div>.hint,.second>div>.hint{
    color: #666;
    font-weight: bold;
}

.first>p,.second>p{
    word-break: break-all;
    white-space: break-spaces;
    text-indent: 2em;
    font-size: 16px;
    line-height: 24px;
}


/* 弹出框 */
.settingBg>.alterBg{
    position: fixed;
    top: 0;
    bottom: 90px;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    background: #f5f5f5;
    user-select: none;
}

.alterBg>.base{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}
.alterBg>.base>.title{
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding: 1em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;

}
.alterBg>.base>.items{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.alterBg>.base>.items>div{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}
.alterBg>.base>.cancle{
    margin: 0;
    margin-top: 1em;
    text-align: center;
    height: 3em;
    line-height: 3em;
}

.alterBg>.offline{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}
.alterBg>.offline>.title{
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding: 1em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;

}
.alterBg>.offline>.items{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.alterBg>.offline>.items>div{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}
.alterBg>.offline>.cancle{
    margin: 0;
    margin-top: 1em;
    text-align: center;
    height: 3em;
    line-height: 3em;
}

.alterBg>.age{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    transition: transform 0.5s ease;
    background-color: white;
}
.alterBg>.age>.title{
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding: 1em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}
.alterBg>.age>.input{
    text-align: center;
    padding-bottom: 1em;
}
.alterBg>.age>.input>input{
    outline: none;
    border: none;
    display: inline-block;
    border-bottom: 1px solid green;
    width: 6em;
    height: 2em;
    border-radius: 0;
}
.alterBg>.age>.cancle{
    margin: 0;
    margin-top: 1em;
    height: 3em;
    line-height: 3em;
    display: flex;
    justify-content: space-around;
}

.alterBg>.location{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    background: white;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    transition: transform 0.5s ease;
}
.alterBg>.location>.title{
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding: 1em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}
.alterBg>.location>.items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}
.alterBg>.location>.items>.left{
    max-height: 20em;
    overflow-y: auto;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.5em;
    font-size: 1.2em;
}
.alterBg>.location>.items>.right{
    max-height: 20em;
    overflow-y: auto;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.5em;
    font-size: 1.2em;
}
.alterBg>.location .province{
    background-color: #ccc;
    padding: 4px;
    border-radius: 3px;
}
.alterBg>.location .province.active{
    background-color: #bde2ff;
    font-weight: bold;
}
.alterBg>.location .city{
    background-color: #ccc;
    padding: 4px;
    border-radius: 3px;
}
.alterBg>.location .city.active{
    background-color: #bde2ff;
    font-weight: bold;
}

.alterBg>.location>.cancle{
    background-color: white;
    margin: 0;
    margin-top: 1em;
    height: 3em;
    line-height: 3em;
    display: flex;
    justify-content: space-around;
}


/* sex role */
.alterBg>.sexRole{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}
.alterBg>.sexRole>.title{
    text-align: center;
    font-weight: bold;
    margin: 0;
    padding: 1em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;

}
.alterBg>.sexRole>.items{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.alterBg>.sexRole>.items>div{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}
.alterBg>.sexRole>.items>.active{
    color: goldenrod;
    font-weight: bold;
}
.alterBg>.sexRole .cancle{
    background-color: white;
    margin: 0;
    margin-top: 1em;
    height: 3em;
    line-height: 3em;
    display: flex;
    justify-content: space-around;
}