

.container{
    padding: 1em;
    height: 100%;
    overflow-y: scroll;
}

.addBtn{
    display: inline-block;
    padding: 8px;
    color: #666;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    font-size: 15px;
    font-weight: bold;
    background: #f1f1f1;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.3s ease;
}
.addBtn:active{
    box-shadow: none;
}

.tip{
    font-size: 15px;
    background: goldenrod;
    padding: 1px 16px;
    margin-top: 10px;
    border-radius: 4px;
    text-indent: 2em;
    color: white;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}


.shareList{
    background-color: #f7f7f7;
    padding: 1em;
    margin-top: 1em;
    border-radius: 4px;
    background: #f7f7f7;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.shareList>.shareItem{
    padding: 2em 0;
    border-bottom: 1px solid lightgray;
}
.shareItem p{
    margin: 0;
}
.shareItem .key{
    margin: 0;
    color: saddlebrown;
    font-weight: bold;
    word-break: break-all;
}
.shareItem .expire{
    margin-top: 0.5em;
    display: flex;
    gap: 1em;
}
.shareItem .itemBtns{
    margin-top: 0.5em;
    display: flex;
    gap: 1em;
}
.shareItem .itemBtns>span{
    cursor: pointer;
    padding: 3px 5px;
    background-color: #daa520b3;
    border-radius: 2px;
    font-size: 0.9em;
    color: beige;
}

.add{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
}
.add>div{
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 4px;
    max-width: 80%;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.add .btns{
    display: flex;
    gap: 1em;
    margin-top: 2em;
}
.add .btns>span{
    display: inline-block;
    padding: 8px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    font-size: 14px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.3s ease;
}
.add .btns>span:active{
    box-shadow: none;
}


.timeAndTimes{
    display: flex;
    gap: 1em;
}

.diyInputC{
    margin: 1em 0;
}
.diyInputC>input{
    outline: none;
    border: none;
    background-color: #f5f5f5;
    border-bottom: 2px solid green;
    display: flex;
    width: 1px;
    visibility:hidden;
    border-radius: 0;
    box-sizing: border-box;
    transition: width 0.5s ease-in-out; /* 添加平滑过渡效果 */
}
.diyInputC>input.active{
    width: 100%;
    visibility:visible;
}