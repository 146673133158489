
.bg{
    position: fixed;
    top: 0;
    width: 100%;
    color: white;
    word-break: break-all;
    white-space: break-spaces;
    box-sizing: border-box;
    font-size: 1.2em;
    font-weight: bold;
    padding: 0 1em;
}

.red{
    background-color: red;
    color: antiquewhite;
    text-align: center;
}
.green{
    background-color: green;
    color: coral;
}
.blue{
    background-color: blue;
    text-align: center;
    font-size: 1.3em;
}
.brown{
    background-color: brown;
    text-align: center;
}