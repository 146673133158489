

.container{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
}


.header{
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative; 
}
.header .a{
    flex: 0 0 auto; /* 不要让它增长或缩小 */
    font-weight: bold;
    font-size: 19px;
    color: green;
}
.header .b{
    position: absolute; 
    left: 0; 
}


.container>.item{
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 4px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.item .status{
    font-size: 0.8em;
    background-color: goldenrod;
    padding: 4px;
    border-radius: 4px;
}
.item .date{
    font-size: 0.9em;
    color: grey;
}
.item .a{
    font-size: 0.8em;
    background-color: gold;
    padding: 4px;
    border-radius: 4px;
}
.item .b{
    font-size: 0.8em;
    background-color: #008000;
    padding: 4px;
    border-radius: 4px;
    color: white;
}
.item .finish{
    font-size: 0.8em;
    background-color: #E91E63;
    padding: 4px;
    border-radius: 4px;
    color: white;
}
.item .c{
    font-size: 0.8em;
    background-color: gold;
    padding: 4px;
    border-radius: 4px;
}
.item .d{
    font-size: 0.8em;
    background-color: gold;
    padding: 4px;
    border-radius: 4px;
}
.item .e{
    font-size: 0.8em;
    background-color: gold;
    padding: 4px;
    border-radius: 4px;
}
.item .f{
    font-size: 14px;
}

.item .meOrHim{
    font-size: 12px;
    color: #ffffff;
    background: orange;
    padding: 4px;
    border-radius: 3px;
    margin-left: 6px;
    margin-right: 6px;
    display: inline-flex;
    width: 1em;
    height: 1em;
    align-items: center;
}