

.container{
    padding: 1em;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    background: #f5f5f5;
}
.contentContainer{
    padding: 1em;
    border-radius: 0.5em;
    margin: 1em 0;
    background: linear-gradient(to right, #ededed, #fffdeb);
    box-shadow: 1px 1px 1px #d3d3d3,-1px -1px 1px #fff;
}
.privateContentContainer{
    padding: 1em;
    border-radius: 0.5em;
    background-color:#2196f359;
    box-shadow: 0 0 0.5em rgba(0,0,0,0.1);
    margin: 1em 0;
    box-shadow: 1px 1px 1px #d3d3d3,-1px -1px 1px #fff;
}

.contentContainer>.itemHeader{
    display: flex;
}
.itemHeader>.avatar{

}
.itemHeader>.avatar>img{
    height: 30px;
    width: 30px;
    border-radius: 3px;
}
.itemHeader>.user{
    font-size: 0.8em;
    color: #000000b5;
    flex: 1;
    margin-left: 1em;
}
.itemHeader>.date{
    font-size: 0.8em;
    color: #000000b5;
}

.contentContainer>.content{
    margin-top: 1em;
    line-height: 1.5em;
}
.content>p{
    margin: 0;
}
.content>p:hover{
    cursor: pointer;
}

/* 动态详情下方按钮 */
.folder{
    padding-top: 1em;
    background-image: linear-gradient( rgba(0,0,0,0), rgba(0,0,0,0),rgba(0,0,0,0.1));
}


/* 图片 */

.imgsContainer{
    display: flex;
    gap: 1em;
}
.imgsContainer>div{
    width: 20%;
    height: 20vw;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.imgsContainer>div>img{
    border-radius: 4px;
}
/* preview */
.imgPreview{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;    
    bottom: 90px;
    overflow-y: scroll;
    text-align: center;
    background-color: #f5f5f5;
}
.imgPreview>img{
    width: 100%;
    object-fit: contain;
}

@media (min-width: 1024px){
    .imgPreview{
        background-color: #f5f5f5;
    }
    .imgPreview>img{
        max-width: 50%;
        width: auto;
        object-fit: contain;
    }
}


.addComment{
    margin-top: 2em;
}


/* 添加按钮 */
.btn{
    padding: 8px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    text-align: center;
    box-shadow: 1px 1px 1px #d3d3d3,-1px -1px 1px #fff;
    transition: all 0.2s;
    display: inline-block;
}
.btn:active{
    box-shadow: none;
}

.content>.contentText{
    word-break: break-all;
    white-space: pre-wrap;
}
.content>.contentTextHide{
    word-break: break-all;
    white-space: pre-wrap;
    height: 4em;
    overflow: hidden;
}

.privateContentContainer>.footer,.contentContainer>.footer{
    display: flex;
    gap: 1em;
}

.footer>span{
    font-size: 0.8em;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.footer>.comment{
    color: white;
    background-color: #d3d3d3;
    padding: 2px 4px;
    border-radius: 3px;
    box-shadow: 1px 1px 1px #d3d3d3,-1px -1px 1px #fff;
    transition: all 0.2s;
}
.footer>.btn{
    color: white;
    background-color: #008000bd;
    padding: 2px 4px;
    border-radius: 3px;
}
.footer>.btn.actived{
    background-color: #d3d3d3;
}

.commentsContainer{
    margin-top: 2em;
}

.commentDetail{
    padding: 1em;
    border-radius: 0.5em;
    background: linear-gradient(to right, #f4f4f4, #ffffff);
    box-shadow: 1px 1px 1px #d3d3d3,-1px -1px 1px #fff;
    
    margin: 1em 0;
}
.commentDetail .content{
    word-break: break-all;
    white-space: pre-wrap;
}
.commentDetail .contentHide{
    word-break: break-all;
    white-space: pre-wrap;
    height: 4em;
    overflow: hidden;
}



.commentDetail .commentHeader{
    display: flex;
}
.commentHeader>.avatar>img{
    height: 30px;
    width: 30px;
    border-radius: 3px;
}
.commentHeader>.user{
    font-size: 0.8em;
    color: #000000b5;
    flex: 1;
    margin-left: 1em;
}
.commentHeader>.date{
    font-size: 0.8em;
    color: #000000b5;
}

.commentDetail .footer{
    display: flex;
    gap: 1em;
}


/* 回复添加回复 */
.commentAddComment{

}
.commentAddCommentBtns{
    display: flex;
    gap: 16px;
}
.commentAddCommentBtns>span{
    background-color: #eee;
    padding: 3px 7px;
    border-radius: 3px;
    color: #333;
    font-size: 14px;
}