

.container{
    padding: 1em;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    user-select: none;
}

/* 设置按钮 */
.settingBtn{
    text-align: end;
}
.settingBtn>span{
    display: inline-block;
    padding: 8px;
    background: #f1f1f1;
    color: #666;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    transition: all 0.3s ease;
}
.settingBtn>span:active{
    box-shadow: none;
    transform: scale(0.9);
}

/* 初衷 */
.header{
    background: #f7f7f7;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    border-radius: 3px;
}
.header>div{
}

.a{
    padding: 1em;
    border-radius: 3px; 
    margin-bottom: 1.5em;
    font-size: 1em;
    background: linear-gradient(to right, #b9d6e163, #ffeb3b0d);
    text-align: center;
}
.b{
    padding: 4px;
    margin-bottom: 1.5em;
    background-color: #f5f5f5;
}

.test{
    position: relative;
    background-color: #f5f5f5;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    border-radius: 3px;
    margin-bottom: 4em;
    padding-bottom: 1em;
}
.scrollContent{
    display: flex; /* 使用Flexbox布局 */
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    box-sizing: border-box;
    color: #666;
}
.scrollContent>div{
    min-width: 100%; /* 确保每个子div至少占满宽度 */
    box-sizing: border-box; /* 包括padding和border在width计算内 */
    display: inline-block; /* 让每个子div保持inline-block特性 */
    vertical-align: top; /* 顶部对齐 */
}
.scrollContent>div>div{
    padding: 0.5em 1em;
    line-height: 1.5em;
}


.scrollBtns{
    display: flex;
    justify-content:space-around;
}
.left,.right{
    display: inline-block;
    padding: 8px;
    background: #f5f5f5;
    color: #666;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}
.left{
    left: 0;
}
.right{
    right: 0;
}
.left:active,.right:active{
    transform: translateY(2px);
    box-shadow: none;
}

