

.page{
    display: inline-flex;
    gap: 10px;
    user-select: none;
}


.page input{
    display: inline-block;
    outline: none;
    width: 4em;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    text-align: center;
    border-radius: 3px;
}

.pre,.next{
    cursor: pointer;
    padding: 5px 14px;
    display: flex;
    transition: all 0.3s;
    border-radius: 3px;
}
.pre:active,.next:active{
    background-color: #666;
}

.pre.disable,.next.disable{
    color: #bbb;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5em;
    gap: 3px;
}