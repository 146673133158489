

.bg{
    height: 100%;
    overflow-y: scroll;
    background-color: #eee;
    box-sizing: border-box;
    user-select: none;
}


.defaultList{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.defaultList>.item{
    width: 80px;
    height: 80px;
    padding: 1em;
    background: white;
}
.defaultList>.item>img{
    width: 100%;
    height: 100%;
}

.selected{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 90px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.5);
}
.selected>.selectedContainer{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selected>.selectedContainer>.selectedContent{
    width: 80%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 3px;
    padding: 1em;
}
.selectedContent>img{

}
.selectedContent>.a{
    margin: 0;
    padding: 0;
    height: 2em;
    font-weight: bold;
    text-align: center;
}
.selectedContent>.b{
    margin: 0;
    padding: 0;
    color: gray;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 1em;
    user-select: none;
}
.selectedContent>.b>span{
    display: inline-block;
    padding: 8px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}
.selectedContent>.b>span:active{
    box-shadow: none;
}
.selectedContent>.c{
  
    text-align: center;
}
.selectedContent>.c>img{
    width: 100%;
    height: 100%;
}


@media (min-width: 900px){
    .defaultList>.item{
        width: 10em;
        height: 10em;
    }
    .selected>.selectedContainer>.selectedContent{
        width:20em;
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 3px;
    }
    .selected>.selectedContainer>.selectedContent>img{
        height: 20em;
        width: 20em;
    }
    
}



.uploadBtn{
    position: fixed;
    bottom: 10em;
    width: 10em;
    left: calc(50% - 5em);
    text-align: center;
    line-height: 2em;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 8px;
    background: #f1f1f1;
    color: #666;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
    transition: all 0.2s ease;
}

.uploadBtn:active{
    box-shadow: none;
}





