

.container{
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 1em;
    background-color: white;
}
.container>title{
    text-align: center;
}

.hint{
    background-color: #eee;
    border-radius: 4px;
    padding: 14px;
    font-size: 16px;
}

.profile>.item>span{
    font-size: 1em;
    font-weight: bold;
    color: #666;
}
.container .item{
    border-bottom: 1px solid #ededed70;
    display: flex;
    justify-content: space-between;
    line-height: 3em;
}
.item > span{
}
.item > div{
    white-space: break-spaces;
    word-break: break-all;
}

.profile>.signature{
    border-bottom: 1px solid #ededed70;
    line-height: 2em;
}
.profile>.signature>span{
    color: #666;
    font-size: 1em;
    font-weight: bold;
}
.profile>.signature>div{
    padding: 1em;
    font-size: 0.9em;
    color: rgba(0,0,0,0.8);
    word-break: break-all;
    white-space: break-spaces;
}
.profile .detail{
    border-bottom: 1px solid #ededed70;
}
.profile>.detail>span{
    color: #666;
    font-size: 1em;
    font-weight: bold;
}
.profile>.detail>div{
    word-break: break-all;
    padding: 1em;
    font-size: 0.9em;
    color: rgba(0,0,0,0.8);
    white-space: break-spaces;
}


.container .hide{
    background-color: #80808024;
    padding: 3px;
    border-radius: 3px;
    margin: 3px 0;
}


h3 .gray{
    background-color: #80808024;
    padding: 3px;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.294);
}
