


.bg{
    position: fixed;
    top: 0;
    bottom: 90px;
    right: 0;
    left: 0;

    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}


.bg>div{
    background: #f5f5f5;
    z-index: 99;
    min-height: 80%;
    padding: 1em;
    margin: 1em;
    border-radius: 6px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.bg>div .hint{
    color: #666;
    font-size: 0.95em;
    font-weight: bold;
}

.btns{
    margin-top: 1em;
    display: flex;
    gap: 1em;
}
.btns>span{
    background-color: #f5f5f5;
    padding: 6px;
    border-radius: 4px;
    font-size: 0.8em;
    color: #666;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: transform 0.1sease, box-shadow 0.1sease;
}
.btns>span:active{
    box-shadow: none;
}