
.container{
    height: 100%;

    overflow-y: scroll;
    background: #f5f5f5;
    color: #444;
}

.header{
    position: sticky;
    top: 0;
    height: 2.5em;
    padding: 0 16px;
    z-index: 999;
}
.header>div{
    display: flex;
    align-items: center;
    background-color: #eeeeeef2;
    justify-content: flex-end;
    gap: 1em;
    height: 100%;
}
.dingwei{
    color: white;
    background-color: #666;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}
.shuaxin{
    display: none;
}
.add{
    color: white;
    background-color: #666;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.contentContainer{
    padding: 0 16px;
}

.item{
    padding: 16px 10px;
    padding-bottom: 6px;
    border-radius: 0.5em;
    margin: 32px 0;
    -webkit-user-select: none;
    user-select: none;
    box-shadow: 1px 1px 1px  #d3d3d3,-1px -1px 1px #fff;
    transition: box-shadow 0.2s;
}
.privateItem{
    background: #2196f359;
}

.item:active{
    box-shadow: none;
}

.item>.itemHeader{
    display: flex;
}
.itemHeader>.avatar{

}
.itemHeader>.avatar>img{
    height: 30px;
    width: 30px;
    border-radius: 3px;
}
.itemHeader>.user{
    font-size: 0.8em;
    color: #000000b5;
    flex: 1;
    margin-left: 1em;
}
.itemHeader>.date{
    font-size: 0.8em;
    color: #000000b5;
}


.imgsContainer{
    display: flex;
    gap: 1em;
}
.imgsContainer>div{
    width: 20%;
    height: 20vw;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.imgsContainer>div>img{
    border-radius: 4px;
}


.folder{
    padding-top: 1em;
    background-image: linear-gradient( rgba(0,0,0,0), rgba(0,0,0,0),rgba(0,0,0,0.1));
}

.item .content{
    word-break: break-all;
    white-space: pre-wrap;
    line-height: 1.6em;
}
.item .hideContent{
    word-break: break-all;
    white-space: pre-wrap;
    max-height: 8em;
    overflow: hidden;
    line-height: 1.6em;
}


.item>.footer{
    margin-top: 1em;
    display: flex;
    gap: 1em;
}
.item>.footer>span{
    font-size: 0.8em;
    display: flex;
    align-items: center;

    color: white;
    background-color: #008000bd;
    padding: 2px 4px;
    border-radius: 3px;
}








/* 覆盖整个页面 禁止滚动 */
.addContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.addContainer>div{
    width: 96%;
    height: 80%;
    background-color: #fff;
    border-radius: 0.5em;
    box-shadow: 0 0 0.5em rgba(0,0,0,0.1);
    padding: 1em;
}

@media (min-width: 1024px) {
    .shuaxin{
        color: white;
        background-color: #666;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
    }
  }