

.bg{
    padding: 12px;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.inputContainer{
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 10px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}

.addBg{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex
    ;
        justify-content: center;
        align-items: center;    display: flex
;
    justify-content: center;
    align-items: center;

}
.addBg>div{
    min-width: 20em;
    background-color: white;
    min-height: 12em;
    border-radius: 3px;
    padding-top: 2em;
    display: flex
;
    flex-direction: column;
    justify-content: space-between;
}

.addBg p{
    text-align: center;
}
.addBg input{
    box-sizing: border-box;
    outline: none;
    border: none;
    border-bottom: 1px solid saddlebrown;
    max-width: 16em;
    line-height: 18px;
    font-size: 20px;
    border-radius: 0;
}