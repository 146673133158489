

.container{
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    background-color: #eee;
}
.container>h3{
    word-break: break-all;
}

.father{
    padding: 10px;
    border-radius: 4px;
    background: #f7f7f7;
    box-shadow: 2px 2px 1px #ddd, -2px -2px 1px white;
}

.father>.replyId{
    font-size: 0.6em;
    color: darkolivegreen;
    text-align: end;
}
.father>.replyHeader{
    display: flex;
}
.father>.replyContent{
    white-space: break-spaces;
    word-break: break-all;
    line-height: 1.6em;
    font-size: 1em;
    color: #666;
}
.father>.replyContentHide{
    white-space: break-spaces;
    word-break: break-all;
    line-height: 1.6em;
    font-size: 1em;
    height: 4em;
    overflow: hidden;
    color: #666;
}


.father>.btns{
    margin-top: 2em;
    font-size: 0.8em;
    color: #000000b5;
    display: flex;
    align-items: center;
    gap: 1em;
}
.father>.btns>span{
   /* display: flex;
   align-items: center; */

   padding: 2px 4px;
   border-radius: 3px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   background-color: #008000bd;
   box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
   transition: all 0.2s;
}
.father>.btns>span.star{
    color: #000000b5;
    background-color: lightgray;
    padding: 2px 4px;
    border-radius: 3px;
}

/*  */
.btn{
    display: inline-block;
    margin: 1em 0;
    padding: 5px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    text-align: center;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.2s;
}
.btn:active{
    box-shadow: none;
}

/*  */
.input{
    margin-top: 2.5em;
    border-radius: 3px;
    background-color: #f7f7f7;
}

.input>div{
    box-shadow: 2px 2px 1px #ddd, -2px -2px 1px white;
    background-color: #f5f5f5;
    padding: 10px;
}


.reply>.replyHeader{
    display: flex;
}
.replyHeader>.user{
    font-size: 0.8em;
    color: #000000b5;
    flex: 1;
    cursor: pointer;
}
.replyHeader>.user:hover{
    color: black;
}
.replyHeader>.date{
    font-size: 0.8em;
    color: #000000b5;
}


.replys{
    margin-top: 2em;
    padding: 1em;
    border-radius: 4px;
    background: #f7f7f7;
    box-shadow: 2px 2px 1px #ddd, -2px -2px 1px white;
}

/* 回复的筛选 */
.replys>.replysFilter{
    display: flex;
    align-items: center;
    gap: 1em;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 0.4em;
    font-size: 0.8em;
    user-select: none;
}
.replys>.replysFilter>span.active{
    font-weight: bold;
}

.replys>.reply{
    border-bottom: 1px solid lightgray;
    padding-top: 1em;
    padding-bottom: 4px;
}

.reply>.id{
    font-size: 0.9em;
    color: #999;
}

.reply>.content{
    word-break: break-all;
    white-space: break-spaces;
    line-height: 1.6em;
    font-size: 1em;
    color: #666;
}
.reply>.contentHide{
    word-break: break-all;
    white-space: break-spaces;
    height: 4em;
    overflow: hidden;
}


.reply>.replyFoot{
    font-size: 0.8em;
    opacity: 0.8;
    display: flex;
    gap: 1em;
    align-items: center;
}
.replyFoot>span{
    padding: 2px 4px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #008000bd;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.2s;
}
.replyFoot>.active{
    color: #000000b5;
    background-color: lightgray;
    padding: 2px 4px;
    border-radius: 3px;
}
