
.alert{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    display: flex;
    justify-content: center;
}
.alert>p{
    height: fit-content;
    padding: 0.5em 1em;
    border-radius: 4px;
}

.success{
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
}
.fail{
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
}
.msg{
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
}