

.container{
    padding: 10px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: #f5f5f5;
}
.container .nodeDetail{
    padding: 16px;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin: 4px;
    margin-bottom: 2.5em;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    
}
.container>.description{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(225 225 225 / 64%);
}
.description>div{
    display: flex;
    justify-content: center;
}
.description .closeBtn{
    color: #333;
    cursor: pointer;
    background-color: wheat;
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 4px;
    padding: 2px;
}
.description>div>.content{
    width: 80%;
    padding: 1em;
    border-radius: 1em;
    background-color: white;
    margin-bottom: 1em;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;

    display: flex;
    flex-direction: column;
    gap: 1em;
}

.title>span{
    font-size: 0.6em;
    color: lightgray;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 0 2px;
    vertical-align: top;
    cursor: pointer;
}


.posts{
    border-radius: 4px;
    background-color: #f5f5f5;
    margin: 0 4px;
    margin-bottom: 1em;
}
.posts>.btns{
    cursor: pointer;
    display: flex;
    gap: 0.5em;
    margin-bottom: 2.5em;
    border-bottom: 1px solid lightgray;
    padding-bottom: 1em;
    justify-content: space-between;
}
.posts>.btns>.left{
    display: flex;
    gap: 1em;
}
.posts>.btns .active{
    color: green;
    border-bottom: 1px solid green;
    font-weight: bold;
}

.posts>.btns .refresh{
    display: none;
}


.addPage{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom:90px;
    background-color: white;
    z-index: 100;
    overflow: auto;
}

.addPage>.addContainer{
    display: flex;
    flex-direction: column;
    padding: 1em;

}
.addContainer>.addTitleInput{
    margin: 0.5em 0;
}
.addContainer>.addContentInput{
    margin: 0.5em 0;
    flex-grow: 1;
}

.btns{
    display: grid;
    grid-template-columns: 2fr 4fr;
    padding: 16px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
/* 做成按钮的形式 */
.btns .addBtn{
    border: 1px dashed green;
    font-size: 13px;
    padding: 2px 4px;
    border-radius: 2px;
    color: green;
}


.btns>.archiveBtn{
    display: inline-block;
    text-align: right;
    padding-right: 2em;
}
.btns>.archiveBtn>span{
    border: none;
    font-size: 0.7em;
    color: #333;
    user-select: none;
}

@media (min-width: 1024px) {

    .posts>.btns .refresh{
        color: goldenrod;
        border-radius: 3px;
        font-size: 14px;
        padding: 4px 6px;
        border: 1px dashed;
        display: block;
    }
    
}