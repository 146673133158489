@keyframes borderGradient {
    0% {
      box-shadow: 0 0 0 1px #e0dad8;
    }
    25% {
      box-shadow: 0 0 0 1px #eed7c5;
    }
    50% {
      box-shadow: 0 0 0 1px #e9e0f4;
    }
    75% {
      box-shadow: 0 0 0 1px #a2d8e5;
    }
    100% {
      box-shadow: 0 0 0 1px #efd3cc8e;
    }
}


.home{
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 1em;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
}
.container{
    user-select: none;
    width: 360px;
    height: 100%;
    background-size: cover;  
    z-index: 1;
}


.top{
    text-indent: 2em;
    font-size: 16px;
    background-image: url('/public/static/find.jpg');
    background-size: cover;  
    padding: 3px 6px;
    border-radius: 3px;
    margin-bottom: 16px;
    line-height: 32px;
}
.top>.hint{
    background-color: rgb(0 0 0 / 0.1);
    color: #fff;
    font-weight: bold;
}


.container>.itemsBg{
    display: flex;
    justify-content: center;
}
.itemsBg>.items{
    width: 100%;
    display: flex;
    gap: 1em;
}
.items>.left{
    width: 50%;
    display: flex;
    gap: 1em;
    flex-direction: column;
}
.items>.right{
    width: 50%;
    display: flex;
    gap: 1em;
    flex-direction: column;
}
.items.loadding>div>div{
    animation: borderGradient 3s infinite linear;
}
.items>div>div:active{
    transform: scale(0.9);
    transition: all 1s;
}

.left>div{
    cursor: pointer;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    background-color: #eee;
    border-radius: 3px;
    transition: all 0.2s ease;
}
.left>div:active{
    box-shadow: none;
}

.left>.find{
    padding: 2px;
}
.left>.find>div{
    padding: 1em;
    height: 140px;
    position: relative;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2em;
    z-index: 2;
}
.find>div::before{
    height: 100%;
    content: "";
    width: 100%;
    background-image: url('https://img.suspar.com/xunta/boys.gif');
    
    background-size: cover;  
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 1px;
}
.find>div>span{
    background: #fcfcfc;
    color: #666;
    padding: 2px 4px;
    position: relative;
    z-index: 3;
}

.left>.profile{
    padding: 2px;
}
.profile>div{
    min-height: 4em;
    padding: 1em;
    position: relative;
    color: #8c8c8c;
    font-weight: bold;
}
.profile>div::before{
    height: 100%;
    content: "";
    width: 100%;
    background-image: url('/public/static/two1.jpg');
    background-size: cover;  
    background-position: 0px -70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 3px;
}
.profile>div>span{
    background: #fcfcfc;
    color: #666;
    padding: 2px 4px;
    position: relative;
    z-index: 3;
}


.right>div{
    min-height: 4em;
    cursor: pointer;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    background-color: #eee;
    border-radius: 3px;
    transition: all 0.2s ease;
}
.right>div:active{
    box-shadow: none;
}

.right>.love{
    position: relative;
    margin: 2px 0;
}
.love:first-child{
    min-height: 4em;
    position: relative;
    color: white;
    text-align: end;
    font-weight: bold;
    padding: 1em;
    background: #f5f5f5;
}
.love>div>span{
    background: #fcfcfc;
    color: #E88D8D;
    padding: 2px 4px;
    position: relative;
    z-index: 3;
}
/* 心形基本样式 */
.heart {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 65%;
    left: 50%;
    margin: -15px 0 0 -15px;
    border-radius: 6px;
    background: #E88D8D;
    transform: rotate(45deg);
    animation: heartbeat 2s infinite; /* 添加动画 */
  }
  
  /* 画上左心房 */
  .heart::before {
    content: '';
    background: #E88D8D;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    left: -11px;
    top: 0px;
  }
  
  /* 画上右心房 */
  .heart::after {
    content: '';
    background: #E88D8D;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0px;
    top: -11px;
  }
  
  /* 定义心跳的动画 */
  @keyframes heartbeat {
    0% {
      transform: rotate(45deg) scale(1); /* 初始大小 */
    }
    25% {
      transform: rotate(45deg) scale(1.2); /* 放大 */
    }
    50% {
      transform: rotate(45deg) scale(1); /* 恢复原始大小 */
    }
    75% {
      transform: rotate(45deg) scale(1.2); /* 再次放大 */
    }
    100% {
      transform: rotate(45deg) scale(1); /* 最终恢复原始大小 */
    }
  }
  
  
  


.right>.record{
    padding: 2px;
}
.right>.record>div{
    height: 140px;
    position: relative;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2em;
    z-index: 2;
    padding: 1em;
}
.record>div::before{
    height: 100%;
    content: "";
    width: 100%;
    background-image: url('/public/static/t12.jpg');
    background-size: cover;  
    background-position: -11px 0px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 1px;
}
.record>div>p>span{
    background: #fcfcfc;
    color: #666;
    padding: 2px 4px;
}
.record>div>p{
    color: white;
    text-align: end;
    font-weight: bold;
    margin: 0;
    position: relative;
    z-index: 3;
}
.record>div>div{
    font-size: 14px;
    color: #8c8c8c;;
    font-weight: lighter;
    margin-top: 1em;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    position: relative;
    z-index: 3;
}




.bottom{
    display: flex;
    gap: 1em;
    padding: 2em 0;
}
.bottom>div{
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #f5f5f5;
    color: #666;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}
.bottom>div:active{
    box-shadow: none;
}
