

.bg{
    padding: 10px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    background-color: white;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header>.pubBtn{
    background: goldenrod;
    border-radius: 2px;
    padding: 3px 5px;
    color: white;
    font-size: 14px;
}

.a{

}
.a>textarea{
    resize: none;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    font-weight: bold;
    border-radius: 0;
    box-sizing: border-box;
}

.b{
    border-bottom: 1px solid #ccc;
    min-height: 10em;
}
.b>textarea{
    resize: none;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    box-sizing: border-box;
}






/* 所有节点 */
.allNodeBg{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    padding: 1em;
}
.allNodeBg>div{
    background-color: white;
    padding: 6px;
    border-radius: 3px;
    min-height: 50vh
}
.allNodeBg .nodes{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.allNodeBg .nodes>span:active{
    border-bottom: 1px solid goldenrod;
}