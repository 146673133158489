.container{
    padding: 14px;
    background-color: #f5f5f5;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    user-select: none;
}

.tabs{
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative; 
}
.tabs .inProgress{
    flex: 0 0 auto; /* 不要让它增长或缩小 */
    font-weight: bold;
    font-size: 19px;
    color: green;
}
.tabs .finished{
    position: absolute; 
    right: 0; 
}




/*  */
.flow{
    background-color: #f5f5f5;
    padding: 6px;
    border-radius: 4px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}