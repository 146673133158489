

.container{
    padding: 1em;
    overflow-y: scroll;
    height: 100%;
    box-sizing: border-box;
}



.tabs{
    display: flex;
    gap: 16px;
    font-size: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ccc;
}

.active{
    color: green;
    text-decoration: underline;
    font-weight: bold;
}

.items{
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.item{
    padding: 1em;
    font-size: 16px;
    font-weight: bold;
    color: goldenrod;
}
.item>span{
    border-bottom: 1px solid goldenrod;
}