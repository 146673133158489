


.container{
    padding: 1em;
    box-sizing: border-box;
}


.header{
    margin: 2em 0;
    display: flex;
    justify-content: space-between;
}


.loverName{
}

.loverName>div{
    word-break: break-all;
    font-size: 1.4em;
    font-weight: bold;
    color: goldenrod;
}

.loveTime{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;
}
.loveTime .a{
    font-size: 1.4em;
    color: #8d763c;
}
.loveTime .b{
    color: #666;
}


.setting{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 90px;
    background-color: white;
}.setting>div{
    overflow-y: auto;
    padding: 1em;
    box-sizing: border-box;
    height: 100%;
}