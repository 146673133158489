

.containerBg{
    position: absolute;
    top: 0;
    bottom: 90px;
    right: 0;
    left: 0;
    height: 100%;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    user-select: none;
}

.containerBg>.container{
    z-index: 99;
    min-height: 80%;
    padding: 1em;
    background: #f7f7f7;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    border-radius: 3px;
}

@media (max-width: 1024px) {
    .containerBg>.container{
        width: calc(100% - 4em);
    }
  }
@media (min-width: 1024px) {
    .containerBg>.container{
        width: 80%;
    }
}


.btn{
    display: inline-block;
    padding: 3px 5px;
    color: #666;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    font-size: 15px;
    background: #f1f1f1;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.3s ease;
}
.btn:active{
    box-shadow: none;
}


.closeBg{

}
.closeBg>p{
    text-align: end;
    font-size: 1.3em;
}



.inputBg{
    
}
.inputBg input{
    outline: none;
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid green;
    width: 100%;
    border-radius: 0;
    text-align: center;
    font-size: 1.5em;
    background: #f5f5f5;
}
.inputBg input::placeholder{
    color: #ccc;
    text-align: center;
    font-size: 1em;
}

.inputBg>input:focus{
    border-bottom: 1px solid green;
}
