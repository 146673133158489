

.container{
    box-sizing: border-box;
    height: 100%;
    padding: 1em;
    overflow-y: auto;
    background-color: #eee;
}

.items{
    display: flex;
    flex-direction: column;
    gap: 1em;
    background: linear-gradient(to right, #ff980026, #03a9f42b);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    margin-bottom: 1em;
}

.items>.avatarAndAccount{
    display: grid;
    grid-template-columns: 40px auto;
    gap: 1em;
}
.items>.avatarAndAccount img{
    width: 40px;
    height: 40px;
    border-radius: 4px;

}
.items>.avatarAndAccount>div{
    display: flex;
    flex-direction: column;
    display: flex;
    gap: 6px;
}
.items>.avatarAndAccount>div>.nickname{
    font-weight: bold;
}
.items>.avatarAndAccount>div>.username{
    font-size: 12px;
    color: #666;
}





.items>.itemNextLine{
    border-bottom: 1px solid lightgray;
    margin-bottom: 1em;
    padding: 10px;
}
.itemNextLine>.placeholder{
    flex: 1;
    font-size: 0.8em;
    color: #000000cf;
}
.itemNextLine>.content{
    margin-top: 6px;
    padding-left: 1em;
    display: flex;
}
.itemNextLine>.content>.text{
    word-break: break-all;
    white-space: pre-wrap;
    color: #000000b5;
    font-size: 0.8em;
    flex: 1;
}


/* 关注和粉丝 */
.followerContainer{
    display: flex;
    justify-content: space-between;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    padding: 10px;
}
.followerContainer>div{
    display: flex;
    gap: 1em;
}
.followerContainer>div>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666;
    font-size: 14px;
}





/* 导航栏容器 */
.myActivity{

}

.myActivity .topicActiveTabs{

}
.myActivity .topicActiveTabs>span{
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    padding: 5px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    transition: opacity .2s;
    background-color: #fff;
    color: #64748b;
    margin: 6px;
    border:1px solid #64748b;
    
}
.myActivity .topicActiveTabs>span.active{

    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    padding: 5px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    transition: opacity .2s;
    background-color: #64748b;
    color: #fff;
    margin: 6px;
}


.edit{

}
.edit>.item{
    background-color: white;
    padding: 1em;
    border-radius: 1em;
    box-shadow: 0 2px 3px rgba(0,0,0,.1);
    margin-bottom: 1em;
}

.edit .headBtns{
    display: flex;
    justify-content: space-between;
}

.edit input{
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgray;
    padding-bottom: 6px;
    outline: none;
}




