

.container{
    margin-top: 1em;
}

.hint{
    background-color: #eee;
    padding: 14px;
    margin-top: 14px;
    border-radius: 4px;
    text-indent: 2em;
}