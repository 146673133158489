
.container *{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: monospace;
}


.container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 16px;
    padding-top: 8px;
    background-color: #f5f5f5;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2),0px -1px 1px #fff;
}

.container>div{
    user-select: none;
    font-size: 24px;
    height: 100%;
    width: 100%;
    text-align: center;
    outline: none;


}



.container span{
    text-decoration: none;
    color: #333;
    display: inline-block;
    width: 100%;
    outline: none;
}
.container>div>.text{
    vertical-align: baseline;
    font-size: 13px;
}


.activeTab{
    color: green;
}
.activeTab span{
    color: green;
}
