
.post{
    margin: 1em 0;
    padding: 1em;
    border-radius: 4px;
    user-select: none;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    background-color: #f5f5f5;
}

.post .ss{
    display: flex;
    gap: 1em;
    align-items: center;
    color: #666;
}
.post .ss>span{
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 0.8em;
    display: flex;
    align-items: center;
}

.post>p>a{
    text-decoration: none;
    color: #333;
    font-size: 0.9em;
}