:root {
    --tileSize: 60px;
    --boardSize: 0px;
}

.container{
    height: 100%;
    box-sizing: border-box;
}
.board {
    display: grid;
    gap: 2px;
    padding: 2px;
    max-height: calc(100% - 5em);
    overflow: scroll;
    margin: 0 auto;
    box-sizing: border-box;
}

.tile {
    background: #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    user-select: none;
    transition: background 0.2s ease;
    box-shadow: inset 0 2px 0 0 #ccc, inset 2px 0 0 0 #ccc, inset 0 -2px 0 0 #666, inset -2px 0 0 0 #666;
    color: transparent;
}

.checked {
    background: #333;
    box-shadow: none;
    color: #fff;
}

.mine {
    background: #ff4444 !important;
    color: #fff !important;
}



.endscreen {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 24px;
}

.endscreen.show {
  display: block;
}

.controls {
    height: 5em;
    box-sizing: border-box;
}
.controls>.times{
    text-align: end;
    color: #666;
    font-size: 14px;
    height: 2em;
    padding-right: 1em;
}
.controls>.btns {
    display: flex;
    gap: 10px;
    justify-content: left;
    height: 3em;
    padding-left: 1em;
    user-select: none;
}
.controls .btns>.btn {
    display: block;
    padding: 8px;
    background: #f1f1f1;
    color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
    transition: all 0.2s ease;
}
.controls .btns>.btn:active{
    box-shadow: none;
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: #eee;
    padding: 20px;
    border-radius: 10px;
    min-width: 300px;
    text-align: center;
}

.modalContent h2 {
    margin-top: 0;
    color: #333;
}

.difficultyButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.difficultyBtn {
    padding: 10px 20px;
    background: #f5f5f5;
    color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
}

.closeBtn {
    padding: 8px 16px;
    background: #f5f5f5;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
}






/* 排行榜 */
.rankBg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 90px;
    box-sizing: border-box;
    background: #eee;
    overflow-y: scroll;

}
.rankBg>div{
    padding: 1em;
}
.rankBg .title{
    padding: 1em;
    display: flex;
    gap: 1em;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
}
.rankBg .title>.active{
    font-weight: bold;
    color: green;
}

.rankBg .rankList{
    padding: 1em;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -4px -4px 4px white;
}
.rankBg .rankList > div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1em 0;
    border-bottom: 1px solid #ccc;
}
.rankBg .rankList .head{
    color: #333;
    font-size: 14px;
    text-align: center;
}
.rankBg .rankList .user{
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}
.rankBg .rankList .times{
    text-align: center;
}
.rankBg .rankList .createTime{
    font-size: 13px;
    color: #666;
    font-style: italic;
    text-align: center;
}