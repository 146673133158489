
.container{
    margin-top: 1em;
    margin-bottom: 1em;
}


.hint{
    margin: 0.5em 0;
    color: #888;
}