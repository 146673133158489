

.container{
    padding: 1em;
    background-color: #f5f5f5;
    user-select: none;
    
    
}

.container>.items{
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 3px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    
}

.items .item{
    border-bottom: 1px solid #f5f5f5;
    padding-top: 20px;
    padding-bottom: 4px;
    padding-left: 4px;
    font-size: 1em;
    font-weight: bold;
}
.items .item:hover{
    color: #666;
}