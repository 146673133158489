
.bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 90px; 
    box-sizing: border-box;
    background-color: #f5f5f5;
    color: #333;
}
.container{
    padding: 16px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}

.container li{
    text-indent: 2em;
}