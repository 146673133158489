
/* flash 组件 */
.flashContainer{
}
.flashContainer>.item{
    padding: 1em;
    border-radius: 4px;
    margin: 1em 0;
    -webkit-user-select: none;
    user-select: none;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    background-color: #f5f5f5;
}
.flashContainer>.privateItem{
    background: #2196f359;
}
.flashContainer>.item>.itemHeader{
    display: flex;
}
.flashContainer>.item>.itemHeader>.user{
    font-size: 0.8em;
    color: #000000b5;
    flex: 1;
}
.flashContainer>.item>.itemHeader>.date{
    font-size: 0.8em;
    color: #000000b5;
}
.flashContainer>.item .content{
    word-break: break-all;
    white-space: pre-wrap;
}
.flashContainer>.item .hideContent{
    word-break: break-all;
    white-space: pre-wrap;
    height: 4em;
    overflow: hidden;
}

.imgsContainer{
    display: flex;
    gap: 1em;
}
.imgsContainer>div{
    width: 20%;
    height: 20vw;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.imgsContainer>div>img{
    border-radius: 4px;
}


.flashContainer>.item>.footer{
    display: flex;
    gap: 1em;
}
.flashContainer>.item>.footer>span{
    font-size: 0.8em;
    color: #000000b5;
    display: flex;
    align-items: center;
}