

.input {
    display: block;
    width: 100%; /* 输入框占满父容器宽度 */
    height: 3em;
    padding: 0.5rem; /* 内边距 */
    font-size: 2em; /* 字体大小为 14px */
    border: 1px solid #ccc; /* 浅灰色边框 */
    border-radius: 0.25rem; /* 圆角 */
    background-color: #fff; /* 白色背景 */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡效果 */
}

.input:focus {
    outline: none; /* 移除默认的聚焦轮廓 */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 聚焦时的阴影效果 */
}