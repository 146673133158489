@keyframes borderGradient {
    0% {
      color:  #df9d8c;
    }
    25% {
      color:  #feb47b;
    }
    50% {
      color:  #a892c1;
    }
    75% {
      color: #a2d8e5;
    }
    100% {
      color:  #ff7f5f8e;
    }
}


.container{
    padding: 1em;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}


.findding{
    font-weight: bold;
    animation: borderGradient 3s infinite linear;
}
.findded{
    color: goldenrod;
    font-size: 1.2em;
    font-weight: bold;
}


.warning{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 90px;
    /* background-color: #eee; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    user-select: none;
}
.warning::before{
    height: 100%;
    content: "";
    width: 100%;
    background-image: url('https://img.suspar.com/xunta/boys.gif');
    background-position-x: -150px;
    background-size: cover;  
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 1px;
}
.setBtn{
    text-align: end;
    padding-right: 1em;
    z-index: 2;
}
.setBtn>span{
    display: inline-block;
    padding: 8px;
    background: #f1f1f1;
    color: #666;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.3s ease;
}
.setBtn>span:active{
    transform: scale(0.9);
    box-shadow: none;
}

.warning>div{
    /* background: #f1f1f1; */
    z-index: 99;
    height: 80%;
    padding: 1em;
    margin: 1em;
    border-radius: 6px;
}
.warning .btn{
    display: block;
    padding: 8px;
    background: #f1f1f1;
    color: #666;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: all 0.5s ease;
}
.warning .btn:active{
    transform: scale(0.9);
    background-color: #f5f5f5;
}
.warning .title{
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    border-radius: 3px;
    margin-bottom: 1.5em;
    padding: 6px;

}
.warning .title p{
    margin: 0;
}
.warning .title >div{
    padding: 10px 12px;
    font-size: 16px;
    height: 80px;
    color: #a0bcbf;
}
.warning .stopMsg{
    padding: 1em;
    color: goldenrod;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    margin-bottom: 1.5em;
    transition: box-shadow 1s ease-in-out;
}
.warning .stopMsg.active{
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
}
.warning .stopMsgBorder{
    background: #eee;
}




.profile{

}
.profile>.item{
    border-bottom: 1px solid #ededed70;
    display: flex;
    justify-content: space-between;
    line-height: 3em;
}
.profile>.item>span{
    font-size: 1em;
    font-weight: bold;
    color: #666;
}
.profile>.signature{
    border-bottom: 1px solid #ededed70;
    line-height: 2em;
}
.profile>.signature>span{
    color: #666;
    font-size: 1em;
    font-weight: bold;
}
.profile>.signature>div{
    padding: 1em;
    font-size: 0.9em;
    color: rgba(0,0,0,0.8);
    word-break: break-all;
    white-space: break-spaces;
}

.profile>.detail{
    border-bottom: 1px solid #ededed70;
}
.profile>.detail>span{
    color: #666;
    font-size: 1em;
    font-weight: bold;
}
.profile>.detail>div{
    word-break: break-all;
    padding: 1em;
    font-size: 0.9em;
    color: rgba(0,0,0,0.8);
    white-space: break-spaces;
}

.container .hide{
    background-color: #80808024;
    padding: 3px;
    border-radius: 3px;
    margin: 3px 0;
}



.confirmBtns{
    display: flex;
    padding: 1em 0;
}
.confirmBtns>div{
    display: flex;
    gap: 1em;
}
.confirmBtns>div>span{
    background-color: #f5f5f5;
    padding: 6px;
    border-radius: 4px;
    font-size: 0.8em;
    color: #666;
    box-shadow: 1px 1px 1px  #d3d3d3, -1px -1px 1px white;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}
.confirmBtns>div>span:active{
    box-shadow: none;
}