

.a{
    text-decoration: none;
    color: goldenrod;
    font-weight: bold;
}

.b{
    text-decoration: none;
    color: goldenrod;
    font-weight: bold;
}