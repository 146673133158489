

.msgList{
    background-color: #f5f5f5;
    padding: 20px;
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 90px;
    display: flex;
    gap: 1em;
    flex-direction: column;
}
.msgList .sentMessage{
    text-align: right;
    word-break: break-all;
    white-space: break-spaces;
    font-size: 14px;
}
.sentMessage>div{
    display: inline-block;
    background-color: #ddd;
    color: #333;
    padding: 4px;
    width: 70%;
    border-radius: 4px;

}
.msgList .receivedMessage{
    text-align: left;
    word-break: break-all;
    white-space: break-spaces;
    font-size: 14px;
}
.receivedMessage>div{
    display: inline-block;
    background-color: white;
    padding: 4px;
    width: 70%;
    border-radius: 4px;
}
/* 时间和内容 */
.msgList .content{

}
.msgList .time{
    font-size: 12px;
    margin-top: 5px;
    opacity: 0.7;
}


.messageInput{
    display: flex;
    gap: 10px;
    padding: 20px;
    background-color: #fff;
    border-top: 1px solid #f5f5f5;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5em;

    width: 100%;
    box-sizing: border-box;
}
.messageInput>input{
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
}

.messageInput button {
    padding: 10px 20px;
    background-color: #007AFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
}
  
.messageInput button:hover {
    background-color: #0056b3;
} 