

.container{
    box-sizing: border-box;
    padding: 1em;
    height: 100%;
    overflow-y: auto;
}

.container>div{
    background-color: white;
    padding: 1em;
    border-radius: 1em;
    box-shadow: 1px 1px 1px  #d3d3d3,-1px -1px 1px #f0f0f0;
    margin-bottom: 1em;
}
.container>.top{
    display: flex;
    flex-direction: column;
    gap: 1em;
}




/*  */
.more{

}
.more>div{
    border-bottom: 1px solid #d9d7d7;
    margin: 6px 0;
    padding-top: 16px;
}
.more>div>a{
    text-decoration: none;
    color: #666;
    font-size: 1.1em;
    font-weight: bold;
}
.more>div>span{
    font-size: 1.1em;
    font-weight: bold;
    color: #333;
}

