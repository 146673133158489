

.container{
    padding: 16px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

.tmpRules{
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
    margin-bottom: 2em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
}
.tmpRules>.first{
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.tmpRules>.first>p{
    display: flex;
    justify-content: space-between;
    padding: 8px;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
}
.tmpRules>.first>.items{
    padding: 8px;
    border-radius: 3px;
}
.tmpRules>.first>.items>p{
    margin: 6px 0;
    font-size: 14px;
    color: #333;
}
.tmpRules>.second{
    padding: 16px;
}
.tmpRules>.second>p{
    font-size: 12px;
    color: #666;
    margin: 0;
    text-align: center;
}



.gongneng{
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
    margin-bottom: 2em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
}
.gongneng>.first{
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.gongneng>.second{
    padding: 16px;
}
.gongneng>.second>p{
    margin: 0;
    display: flex;
    gap: 1em;
}
.gongneng>.second>p>span{
}
.gongneng>.second>p>span.active{
    font-weight: bold;
}