

.container>.content{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 90px; 
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.bottom{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 90px;
} 
