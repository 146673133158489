

.container{
    padding: 1em;
}

.btns{
    display: flex;
    justify-content: center;
    margin-top: 1em;
}
.btns > div{
    display: flex;
    gap: 10px;
}
.btns span{
    background-color: #eee;
    padding: 3px 6px;
    border-radius: 4px;
}
.btns span.active{
    background-color: goldenrod;
}
